/***************************************************
  Imports
***************************************************/

import React, { useEffect } from "react"

import Layout from "../components/Layout"
import Button from "../components/Button"

import ContactUsGraphic from "../images/contact-us-graphic.svg"
import IconLocation from "../images/icon-location.svg"

/***************************************************
  Component
***************************************************/

export default () => {
  useEffect(() => {
    const getFormScript = document.createElement("script")
    const reCaptchaScript = document.createElement("script")

    reCaptchaScript.src =
      "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=6Lcy19cZAAAAAPz9sQWVP93Zgecmf9ohIaGYMUmM"
    getFormScript.appendChild(
      document.createTextNode(`
      var onloadCallback = () => {
        grecaptcha.ready(() => {
        grecaptcha.execute('6Lcy19cZAAAAAPz9sQWVP93Zgecmf9ohIaGYMUmM', {action: 'homepage'})
          .then(token => {
            document.getElementById('captchaResponse').value = token;
          })
        })
      }`)
    )

    document.body.appendChild(getFormScript)
    document.body.appendChild(reCaptchaScript)
    return () => {
      document.body.removeChild(getFormScript)
      document.body.removeChild(reCaptchaScript)

      const badge = document.getElementsByClassName("grecaptcha-badge")[0]
      if (badge) badge.remove()
    }
  }, [])

  return (
    <Layout
      headerTheme="light"
      className="contact-us"
      title="Contact Us"
      description="If you like support for our apps or have any other questions, please fill out the form below and submit it to us."
    >
      <section className="section-hero">
        <div className="col-3 span-5">
          <h1>Contact Us</h1>
          <p>
            If you like support for our apps or have any other questions, please
            fill out the form below and submit it to us. We will get back to you
            within 1-2 business days.
          </p>
        </div>
      </section>

      <section className="section-hero-illustration">
        <img className="col-9 span-4" src={ContactUsGraphic} alt="Contact Us" />
      </section>

      <section className="section-offices">
        <div className="col-4 span-4">
          <h2>Our Offices</h2>
          <div>
            <h5>Dallas, Texas Office</h5>
            <p>
              SimCrest, Inc.
              <br />
              1914 Skillman Street, Suite 110-319
              <br />
              Dallas, Texas 75206
              <br />
              P: (214) 644-4000
              <br />
              M: info@simcrest.com
            </p>
            <a
              href="https://www.google.com/maps/place/1914+Skillman+St,+Dallas,+TX+75214/@32.813218,-96.7633191,17z/data=!3m1!4b1!4m5!3m4!1s0x864e9f4e14677087:0xfb84e1688fd8b2a0!8m2!3d32.813218!4d-96.7611304"
              target="_blank"
            >
              <img src={IconLocation} alt="location" />
              <span>View on Map</span>
            </a>
          </div>
          <div>
            <h5>Miami, Florida Office</h5>
            <p>
              SimCrest, Inc.
              <br />
              1749 NE Miami Ct, Suite 609
              <br />
              Miami, Florida 33132
              <br />
              P: (305) 767-2370
              <br />
              M: info@simcrest.com
            </p>
            <a
              href="https://www.google.com/maps/place/1749+NE+Miami+Ct,+Miami,+FL+33132/@25.792119,-80.1957517,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9b6a5f8edf1bf:0x45501561c447bd40!8m2!3d25.792119!4d-80.193563"
              target="_blank"
            >
              <img src={IconLocation} alt="location" />
              <span>View on Map</span>
            </a>
          </div>
          <div>
            <h5>Los Angeles, California Office</h5>
            <p>
              SimCrest, Inc.
              <br />
              3415 South Sepulveda Blvd., Suite 1100
              <br />
              Los Angeles, California 90034
              <br />
              P: (310) 873-3220
              <br />
              M: info@simcrest.com
            </p>
            <a
              href="https://www.google.com/maps/place/3415+S+Sepulveda+Blvd,+Los+Angeles,+CA+90034/@34.0205136,-118.4263942,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2ba4cf135b6bb:0x334d783f14f67b2e!8m2!3d34.0205136!4d-118.4242055"
              target="_blank"
            >
              <img src={IconLocation} alt="location" />
              <span>View on Map</span>
            </a>
          </div>
        </div>
        <form
          className="col-8 span-4"
          action="https://getform.io/f/ffb2957d-ec44-4487-9d3d-1bbab87cc90b"
          method="POST"
        >
          <label>Your Name</label>
          <input name="name" type="text" required />
          <label>Email</label>
          <input name="email" type="text" required />
          <label>Phone</label>
          <input name="phone" type="text" />
          <label>App Name</label>
          <input name="appname" type="text"></input>
          <label>Message</label>
          <textarea name="message" />
          <input
            type="hidden"
            id="captchaResponse"
            name="g-recaptcha-response"
          ></input>
          <Button className="button" type="submit">
            SUBMIT
          </Button>
        </form>
      </section>
    </Layout>
  )
}
